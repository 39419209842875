import {
  createEffect,
  onCleanup,
  createMemo,
  on,
  createSignal,
  createSelector,
  onMount,
  Show,
} from "solid-js";
//@ts-ignore
import { ElementNode, IntrinsicTextNodeStyleProps, View, hexColor, deg2Rad, Text } from "@lightningtv/solid";
import { useNavigate } from "@solidjs/router";
import { useParams, useLocation } from "@solidjs/router";
import { isBuffering, playListPassword, playListUrl, playListUsername, setIsBuffering } from "../../../state";
import videoPlayer from "../../videoPreview";
import { ProgressBar } from "@lightningtv/solid-ui";
import LoadingIndicator from "../loading/loading";
import { Buffer } from "buffer";

const Player = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = createSignal(null);
  const [isVideoPlaying, setIsVideoPlaying] = createSignal(false);
  const [videoDuration, setVideoDuration] = createSignal(0);
  const [currentTime, setCurrentTime] = createSignal(0);
  const [contentType, setContentType] = createSignal("");
  const [nameMovie, setNameMovie] = createSignal("");

  const location = useLocation();

  const [controlsVisible, setControlsVisible] = createSignal(true); // Control visibility state
  let controlsTimeout;

  const showControls = () => {
    setControlsVisible(true);
    resetControlsTimeout();
  };

  const hideControls = () => {
    setControlsVisible(false);
  };

  const resetControlsTimeout = () => {
    clearTimeout(controlsTimeout);
    controlsTimeout = setTimeout(hideControls, 6000); // Hide controls after 6 seconds
  };

  createEffect(() => {
    const params = new URLSearchParams(location.search);
    const num: any = params.get("str");
    const type = params.get("type"); // Get the content type from the query
    const name = params.get("name");
    // Ensure currentIndex is set initially
    if (num !== null) {
      setCurrentIndex(num);
    }
    // Set content type (serie or movie)
    if (type) {
      setContentType(type);
    }
    if (name) {
      setNameMovie(name);
    }
  });

  createEffect(() => {
    const streamOfID = currentIndex();
    const type = contentType();
    const videoUrl = `${playListUrl()}/${type}/${playListUsername()}/${playListPassword()}/${streamOfID}.mp4`;
    const streamUrlProxy = Buffer.from(
      `${playListUrl()}/${type}/${playListUsername()}/${playListPassword()}/` + streamOfID + ".mp4",
      "utf-8",
    ).toString("base64");

    // const videoUrl = `http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4`;

    videoPlayer
      .playVideo(
        localStorage.getItem("vpn") == "on"
          ? localStorage.getItem("proxy_url")
            ? localStorage.getItem("proxy_url") + streamUrlProxy
            : videoUrl
          : videoUrl,
      )
      .then(() => {
        console.log("Video is now playing.");
        setIsVideoPlaying(true);
        resetControlsTimeout();
        setIsBuffering(false);

        // setVideoDuration(videoPlayer.getDuration());

        console.log("videoDuration22", videoPlayer.getDuration());

        const getVideoDuration = async () => {
          try {
            const duration = await videoPlayer.getDuration(); // Wait for the Promise to resolve
            console.log("Video Duration brenda:", duration);
            setVideoDuration(duration);
          } catch (error) {
            console.error("Error getting video duration:", error);
          }
        };
        getVideoDuration();

        const timeUpdateHandler = () => {
          setCurrentTime(videoPlayer.getCurrentTime());
        };

        videoPlayer.onTimeUpdate(timeUpdateHandler);

        onCleanup(() => {
          videoPlayer.onTimeUpdate(() => {});
        });
      })
      .catch(error => {
        console.error("Error playing video:", error);
        setIsVideoPlaying(false);
      });
  });

  const onCustomEscape = () => {
    videoPlayer.destroy(); // Call destroy directly (no Promise needed)
    console.log("Video player destroyed and video element removed.");

    // After destruction, navigate after a slight delay (setTimeout)
    setTimeout(() => navigate(-1), 100);
  };

  const progress = () => {
    const duration = videoDuration();
    const current = currentTime();
    return duration > 0 ? current / duration : 0;
  };

  // Toggle play/pause state
  const togglePlayPause = () => {
    if (isVideoPlaying()) {
      videoPlayer.pause();
    } else {
      videoPlayer.play();
    }
    setIsVideoPlaying(!isVideoPlaying());
    showControls();
  };

  const seekTime = seconds => {
    try {
      const current = currentTime();
      const duration = videoDuration();

      // Validate current and duration values
      if (!isFinite(current) || !isFinite(duration)) {
        console.error("Invalid current time or video duration.");
        return;
      }

      // Calculate the new seek time
      const newTime = current + seconds;

      // Clamp newTime within valid bounds
      const clampedTime = Math.max(0, Math.min(newTime, duration));
      console.log("Seeking to clampedTime:", clampedTime);

      // Seek if the player is ready
      if (isFinite(clampedTime)) {
        videoPlayer.seek(clampedTime);
        setCurrentTime(clampedTime);
      } else {
        console.error("player is not ready or clampedTime is invalid.");
      }
    } catch (error) {
      console.error("Error in seekTime function:", error.message);
    }
  };

  const onKeyPressActions = () => {
    showControls(); // Show controls when a key is pressed
    resetControlsTimeout(); // Reset timeout to hide controls
  };

  const formatTime = seconds => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

    if (hours > 0) {
      return `${hours}:${minutes}:${secs}`;
    } else {
      return `${minutes}:${secs}`;
    }
  };

  // function formatTime(ms) {
  //   let totalSeconds = Math.floor(ms / 1000);
  //   let hours: any = Math.floor(totalSeconds / 3600);
  //   let minutes: any = Math.floor((totalSeconds % 3600) / 60);
  //   let seconds: any = totalSeconds % 60;
  //   // Pad hours, minutes, and seconds with leading zeros if needed
  //   hours = String(hours).padStart(2, '0');
  //   minutes = String(minutes).padStart(2, '0');
  //   seconds = String(seconds).padStart(2, '0');
  //   return `${hours}:${minutes}:${seconds}`;
  // }

  return (
    <View>
      <View
        onEscape={() => {
          onCustomEscape();
        }}
        onEnter={() => {
          togglePlayPause();
        }}
        onUp={() => {
          resetControlsTimeout();
        }}
        onDown={() => {
          resetControlsTimeout();
        }}
        onRight={() => {
          seekTime(15);
          onKeyPressActions();
        }}
        onLeft={() => {
          seekTime(-15);
          onKeyPressActions();
        }}
        autofocus
      >
        <Show when={isBuffering()}>
          <View x={800} y={400}>
            <LoadingIndicator />
          </View>
        </Show>
        <Show when={controlsVisible()}>
          <View
            src={"https://i.ibb.co/sHw6rPz/Pngtree-black-gradient-vertical-gradient-element-5441580.png"}
          ></View>
          <View
            src={
              "https://i.ibb.co/NSvf1HZ/Lovepik-com-380148632-black-transparent-vertical-linear-gradient-gradient-element-transparency-black.png"
            }
          ></View>

          <Text style={{ fontSize: 62 }} x={50} y={40}>
            {nameMovie()}
          </Text>

          <Text y={950} x={1600} style={{ fontSize: 24 }}>
            {`${formatTime(currentTime())} / ${formatTime(videoDuration())}`}
          </Text>
          <ProgressBar
            progress={progress()}
            borderRadius={5}
            height={10}
            width={1650}
            y={1000}
            x={100}
            value={0}
            progressRadius={100}
          />

          <Show when={!isBuffering()}>
            <View
              src={
                isVideoPlaying()
                  ? "https://i.ibb.co/MnqnLDD/pause.png"
                  : "https://i.ibb.co/ZWM6s0t/play-button-arrowhead.png"
              }
              x={870}
              y={500}
              width={50}
              height={50}
            />
          </Show>
        </Show>
      </View>
    </View>
  );
};

export default Player;
