import { setIsBuffering } from "../state";

let videoElement;

export const state = {
  playingState: false,
};

const init = (element, zIndex = -1, width = "100vw", height = "100vh", context) => {
  if (!videoElement) {
    videoElement = element || document.createElement("video");
    videoElement.preload = "auto"; // Hint to browser to preload video data

    videoElement.style.cssText = `
        position: absolute;
        top: ${context === "preview" ? "50%" : "0"};
        left: ${context === "preview" ? "50%" : "0"};
        z-index: ${zIndex};
        width: ${width};
        height: ${height};
        background-color: black;
        transform: ${context === "preview" ? "translate(-51.5%, -148%)" : "none"};
      `;

    videoElement.addEventListener("waiting", () => {
      setIsBuffering(true); // Show buffering indicator
      console.log("Buffering: Loading...");
    });

    videoElement.addEventListener("playing", () => {
      setIsBuffering(false); // Stop buffering indicator
      console.log("Buffering: Playing");
    });

    videoElement.addEventListener("error", () => {
      console.error("Video error occurred.");
      setIsBuffering(true); // Handle errors by setting buffering state
    });

    document.body.insertBefore(videoElement, document.body.firstChild);
  }

  videoElement.autoplay = false;
};

/**
 * Loads a video URL into the default player.
 * @param {Object} config - The player configuration.
 */
const load = config => {
  if (!videoElement) {
    throw "Video element not initialized yet";
  }

  setIsBuffering(true); // Start loading indicator
  try {
    videoElement.src = config.streamUrl; // Set video source URL
    videoElement.load(); // Load the video
  } catch (error) {
    console.error("Error loading stream:", error);
    setIsBuffering(true); // Handle load failure
  }
};

const play = () => {
  videoElement
    .play()
    .then(() => {
      state.playingState = true;
      setIsBuffering(false); // Stop buffering indicator
    })
    .catch(error => {
      console.error("Error starting playback:", error);
      setIsBuffering(true); // Handle playback failure
    });
};

const pause = () => {
  videoElement.pause();
  state.playingState = false;
};

const destroy = () => {
  if (videoElement) {
    videoElement.remove();
    videoElement = null;
  }
};

const getDuration = () => {
  if (!videoElement) {
    console.error("Video element is not initialized");
    throw new Error("Video element is not initialized");
  }

  return new Promise((resolve, reject) => {
    // Check if the duration is already available
    if (!isNaN(videoElement.duration)) {
      console.log("Video element duration1:", videoElement.duration);
      resolve(videoElement.duration);
    } else {
      // Wait for the metadata to load
      videoElement.addEventListener("loadedmetadata", () => {
        console.log("Video element duration2:", videoElement.duration);
        resolve(videoElement.duration);
      });

      // Handle errors if metadata fails to load
      videoElement.addEventListener("error", e => {
        console.error("Error retrieving video duration:", e);
        reject(new Error("Failed to load video metadata"));
      });
    }
  });
};

const getCurrentTime = () => {
  if (!videoElement) {
    throw new Error("Video element is not initialized");
  }
  return videoElement.currentTime;
};

const onTimeUpdate = callback => {
  if (!videoElement) {
    throw new Error("Video element is not initialized");
  }
  videoElement.addEventListener("timeupdate", callback);
};

export const playVideo = async (streamUrl, context) => {
  let zIndex = -1;
  let width = "100vw";
  let height = "100vh";

  if (context === "preview") {
    zIndex = 1;
    width = "30vw";
    height = "30vh";
  } else if (context === "splash") {
    zIndex = 1;
    width = "100vw";
    height = "100vh";
  }

  init(null, zIndex, width, height, context);

  // Load the video URL into the player
  load({ streamUrl });

  // Start playback
  play();
};

const seek = time => {
  if (!videoElement) {
    throw new Error("Video element is not initialized");
  }
  videoElement.currentTime = time; // Set the current time

  // Autoplay the video after seeking
  videoElement.play().catch(error => {
    console.error("Error trying to play the video:", error);
  });
};

export default {
  init,
  load,
  play,
  pause,
  getCurrentTime,
  getDuration,
  onTimeUpdate,
  seek,
  state,
  destroy,
  playVideo,
};
